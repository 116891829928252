.diy-video-container {
  padding: 20px 20px 100px;
  position: relative;
  z-index: 100;
  width: 100%;
  text-align: center;
  max-width: 750px;
}

.public-maincontainer-videocontainer {
  /* margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden; */
}

h1 {
  text-align: center;
}

.video-player-container {
  position: relative;
  height: 450px;
  width: 100%;
}

.video-player {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 500px; /* set a default height for the video player */
}

.button-container {
  margin-top: 20px;
  text-align: center;
}
.enroll-btn {
  text-align: center;
}
.enroll-btn button {
  margin-top: 10px;
  background-color: #262528;
  color: #ffffff;
  border: none;
  padding: 6px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  width: 150px; /* set a default width for the button */
  height: 40px; /* set a default height for the button */
}
.consultation {
  background-color: #262528;
  padding: 20px;
  color: #fff;
  border-radius: 6px;
  width: fit-content;
}

/* Responsive design elements */

/* For screens with a maximum width of 1200px */
@media (max-width: 1200px) {
  .video-player {
    height: 400px; /* reduce the height of the video player */
  }
  .button-container button {
    font-size: 14px; /* reduce the font size of the button text */
    width: 120px; /* reduce the width of the button */
    height: 30px; /* reduce the height of the button */
  }
}

/* For screens with a maximum width of 992px */
@media (max-width: 992px) {
  .video-player {
    height: 300px; /* reduce the height of the video player */
  }
  .button-container button {
    font-size: 12px; /* reduce the font size of the button text */
    width: 100px; /* reduce the width of the button */
    height: 25px; /* reduce the height of the button */
  }
}

/* For screens with a maximum width of 768px */
@media (max-width: 768px) {
  .video-player {
    height: 250px; /* reduce the height of the video player */
  }
  .button-container button {
    font-size: 10px; /* reduce the font size of the button text */
    width: 80px; /* reduce the width of the button */
    height: 20px; /* reduce the height of the button */
  }
}

/* For screens with a maximum width of 576px */
@media (max-width: 576px) {
  .video-player {
    height: 200px; /* reduce the height of the video player */
  }
  .button-container button {
    font-size: 8px; /* reduce the font size of the button text */
    width: 60px; /* reduce the width of the button */
    height: 15px; /* reduce the height of the button */
  }
}
