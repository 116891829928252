.journey-container{
  overflow: hidden;
  height: calc(100vh - 86px);
}

.chatbot-container {
  height: 100%;
  background-color: white;
  border: 1px solid #002b9014;
}
.journey-config-container{
  height: 100%;
  overflow: auto;
}