.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  padding: 20px;
  box-sizing: border-box;
}
.login-box {
  padding: 20px;
}
.login-content,
.forgot-password {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-top: 20px;
}
.login-form-container {
  margin-top: 40px;
  width: 350px;
}
.submit-button {
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}
.login-container .ant-input-affix-wrapper {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}
.login-container .ant-input-affix-wrapper:hover,
.login-container .ant-input-affix-wrapper:active {
  box-shadow: none;
}
.login-container .ant-form-item-explain-error {
  font-size: 12px;
}
.forgot-password {
  text-align: center;
  font-weight: 500;
}
.forgot-password a {
  cursor: pointer;
  color: #24292e;
  text-decoration: none;
}

@media (max-width: 468px) {
  .login-form-container,
  .login-box {
    width: 100%;
  }
}
