.video-placeholder {
  height: 400px;
  max-height: 400px;
  width: 100%;
  background-color: rgb(203, 198, 198);
}
.avatar-thumbnail {
  height: 200px;
  object-fit: cover;
  background-color: #eff3f5;
  width: 100%;
}
.avatar-container {
  padding: 20px;
}
.add-avatar {
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 30px;
  color: gray;
  padding: 150px 50px;
  background-color: #eff3f5;
}
.add-avatar:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.add-new-avatar {
  font-size: 20px;
  color: #2b2b2b;
}
.avatar-card-container {
  height: 100%;
  /* width: 400px;
  min-width: 400px; */
  overflow: hidden;
}
.avatar-card-container,
.avatar-card-container .ant-card-body {
  padding: 0;
}
.avatar-details {
  padding: 10px;
  text-align: left;
}
.avatar-detail-container {
  padding: 10px 0;
}
.avatar-detail-container .ant-divider-horizontal {
  margin: 10px 0;
}
.more-options {
  padding: 4px;
  background-color: white;
  width: fit-content;
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 20px;
}
.more-items {
  display: none;
}
.avatar-card-container:hover .more-items {
  display: flex;
}
.default-avatar {
  position: absolute;
  top: 16px;
  left: 16px;
}
.avatar-skeleton-container {
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 30px;
  color: gray;
  background-color: #eff3f5;
  padding: 10px;
}
.avatar-title h1 {
  text-align: left;
}
.avatar-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 0, 0, 0.06) 63%
  );
  background-size: 400% 100%;
  animation-name: overlay;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
.avatar-date {
  color: rgb(167, 166, 166);
}
.avatar-card-container .ant-skeleton.ant-skeleton-element {
  width: 100%;
}
.avatar-card-container .ant-card-meta-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar-list-wrapper {
  overflow: hidden;
  padding: 15px;
}
@keyframes overlay {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
.avatar-loader-container {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.avatar-loader-container .loader {
  position: relative;
  top: auto;
  left: auto;
}
.avatar-tab{
  margin-top: 10px;
}
.avatar-thumbnail-img{
  width: 100%;
  height: auto;
}