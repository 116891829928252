.course-steps {
  padding: 16px;
  height: 100%;
  overflow: hidden;
}
.course-steps-container {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 2px 3px 12px 0px #0000000a;
  border: 1px solid #002b9014;
  padding: 24px;
}
.course-step-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.script-option {
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.script-option:hover {
  background-color: #e4ebf6;
}
.script-option span,
.script-option svg {
  min-width: max-content;
}
.custom-col {
  width: 100%;
}

@media (max-width: 1400px) {
  .custom-col {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .custom-col {
    width: 70%;
  }
}
