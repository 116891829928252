.chatbot {
  height: 100%;
  font-family: "Poppins", sans-serif;
}
.chatbot-title {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  padding: 20px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 120px;
}

.model-dropdown {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  appearance: none;
  height: 30px;
  max-width: 206px;
}

.model-dropdown:focus {
  outline: none;
  border-color: #4d4d4d;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Add styling for the dropdown arrow */
.model-dropdown::after {
  content: "▼";
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.chatbot-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chat-container {
  flex: 1;
  overflow: auto;
  padding: 20px 20px 0;
  scroll-behavior: smooth;
}
.chat-input-wrap {
  padding: 10px;
}
.chat-input {
  position: relative;
  display: flex;
  background-color: white;
  align-items: flex-end;
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
  border-radius: 6px;
}

.chat-input .ant-input-affix-wrapper,
.chat-input .ant-input {
  /* height: 64px; */
  box-shadow: none;
  border: none;
}
.chat-avatar {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #596066;
  min-width: fit-content;
}
.chats .chat-avatar {
  float: right;
}

.chats .chat-body {
  display: block;
  margin: 10px 0;
  overflow: hidden;
}
.chatbot .chat-messages {
  padding-bottom: 10px;
}
.chats .chat-body .chat-content {
  float: right;
  font-size: 12px;
  font-weight: 500;
  padding: 0.7rem 1rem;
  clear: both;
  max-width: 75%;
  background-color: #ebeef3;
  border-radius: 20px 2px 20px 20px;
}

.chats .chat-body .chat-content p {
  margin: 0;
}

/* Chat area left */
.chat-left .chat-avatar {
  float: left;
}

.chat-left .chat-body .chat-content {
  float: left;
  border-radius: 2px 20px 20px 20px;
  border: 1px solid #eff0f6;
  color: #000000;
  background-color: #ffffff;
}

/* Chat form of user area */
.chat-app-form {
  height: 60px; /* Assuming $chat-head-footer-height is defined elsewhere */
  padding: 0 1rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5; /* Assuming $border-color is defined elsewhere */
}
.chats .chat-user {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.chat-left .chat-user {
  flex-direction: row;
}
.chat-username {
  font-size: 14px;
  color: #596066;
}
.generate-button {
  font-size: 12px;
  margin-left: 5px;
  padding: 8px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px #0000001f,
    /* First shadow */ 0px 0px 0px 1px #676e7629,
    /* Second shadow */ 0px 2px 5px 0px #676e7614; /* Third shadow */
  background-color: #262528;
  color: #ffffff;
  cursor: pointer;
}
.scroll-button {
  margin-bottom: 1rem;
  position: sticky;
  bottom: 20px; /* Adjust as needed */
  left: 90%;
  opacity: 0;
  border-radius: 100%;
  height: 45px;
  width: 45px;
  /* border: 1px solid #000000; */
  border: none;
  background-color: #000000;
  cursor: pointer;
}
.scroll-button.visible {
  opacity: 100;
}
.chatbot hr {
  width: 100%;
  background-color: #002b9014;
}
.send-button {
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* position: absolute;
  right: 1.75rem;
  bottom: 1.25rem; */
  height: 100%;
  height: fit-content;
}
.ant-skeleton.ant-skeleton-element {
  width: 75%;
}

.chatbot-input {
  height: 100%;
}
.empty-chat {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-chat-loader {
  /* float: left; */
  border-radius: 2px 20px 20px 20px;
  border: 1px solid #eff0f6;
  color: #000000;
  background-color: #ffffff;
  width: fit-content;
  padding: 16px 24px;
}
.chat-loader {
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #262528 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
/* .chatbot .ant-drawer .ant-drawer-content-wrapper {
  top: 64px;
} */
.chatbot .ant-drawer .ant-drawer-header,
.chat-assistant .ant-drawer .ant-drawer-header {
  padding: 10px;
}
.chatbot .chat-body:hover .copy {
  display: flex;
}
.chat-body .copy {
  /* position: absolute; */
  margin-left: 10px;
  display: none;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  color: #262528;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #262528;
}
.chatbot-action {
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  height: 36px;
  cursor: pointer;
  display: flex;
}
.voice-chat {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.voice-chat-container {
  height: 100%;
  width: 100%;
}
.voice-chat-wrapper {
}
.voice-chat-answer {
  width: 50%;
}
.voice-chat-listener {
  width: 50%;
}
.voice-chat-close {
  width: 48px;
  height: 48px;
  background-color: #112a4c;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border: none;
  color: white;
  cursor: pointer;
}

.chatbot-popup.expand {
  border-radius: 0;
  width: 100%;
  height: calc(100% - 86px);
  top: 86px;
  left: 0;
}
.expand-messages {
  width: 50%;
  margin: 0 auto;
}
.voice-chat-listening {
  background: radial-gradient(
    54.17% 54.17% at 50% 50%,
    #ffffff 0%,
    #c7ebf4 56.68%,
    #90eeed 100%
  );
  border-radius: 100%;
  width: 350px;
  height: 350px;
  margin-bottom: 10px;
}
.interrupt-chat{

  
}