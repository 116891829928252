.journey-header {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
/* actions */
.journey-action {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.sort-action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort-action .sort-prefix {
  width: max-content;
  margin-top: 2px;
}
.sort-action .ant-select {
  min-width: 135px;
}
.sort-options.ant-select-dropdown {
  min-width: 250px;
}

.filter-button,
.create-button {
  height: 36px;
  padding: 6px 20px;
  display: flex;
}
.journey-action .search-input {
  width: 220px;
  background-color: #f5f6f7;
  height: 36px;
}
.journey-title-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.journey-title-wrapper:hover span {
  color: rgba(0, 0, 0, 0.664);
}
.journey-title-wrapper span {
  font-weight: 600;
  color: black;
}
.journey-title-wrapper .title-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
}
.sort-options .ant-select-item-option {
  flex-direction: row-reverse;
  gap: 2px;
}
.journey-wrapper {
  border: 1px solid #e6e9f1;
  box-shadow: 6px 6px 24px 0px #5a668314;
  border-radius: 8px;
}
.segmented-title{
  padding: 15px 0;
}
