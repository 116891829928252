.app-dashboard-container {
  display: flex;
  height: 100%;
  color: #fff;
  overflow: hidden;
  border-radius: 12px;
}
.app-sidebar {
  color: white;
  padding: 20px;
  width: 200px;
}
.app-logo {
  font-size: 24px;
  margin: 0 0 20px;
}
.app-nav {
}
.app-nav-item {
  padding: 8px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  gap: 10px;
}
.app-nav-item.selected {
  border-radius: 6px;
  opacity: 1;
  color: white;
}
.app-menu-button {
  width: 100%;
  margin-bottom: 10px;
}
.app-main-content {
  flex: 1;
  padding: 20px 40px;
  overflow: auto;
  scrollbar-width: none;
}
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.app-user-icon {
  font-size: 24px;
}
.app-journey-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
.app-journey-card {
  /* padding: 20px; */
  border-radius: 10px;
  flex: 1;
  color: white;
  max-width: 320px;
  min-width: 320px;
}
.app-journey-card.ant-card .ant-card-body {
  padding: 0;
}
.app-journey-card .ant-divider {
  margin: 0;
  border-block-start: 1px solid rgba(253, 253, 253, 0.12);
}
.app-bg-green {
  background-color: #004d40;
}
.app-bg-blue {
  background-color: #003366;
}
.app-continue-button {
  margin-top: 10px;
}
.app-calendar-section {
  width: 400px;
  padding: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app-tasks .app-task-card {
  margin-top: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff14;
}
.app-task-name {
  color: white;
  font-weight: 500;
}
.app-task-tag {
  background-color: #ffffff1a;
  color: #ffffff99;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  font-size: 12px;
}
.app-tag {
  color: #ffffffb3;
  background-color: #ffffff1a;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 10px;
}
.app-journey-details {
  padding: 20px;
}
.app-journey-button {
  height: 40px;
  padding: 8px 15px;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  flex: 1;
  display: flex;
  justify-content: center;
}
.app-start-jounrey {
  border-radius: 99px;
  background-color: #ffffff14;
  padding: 0 15px;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-task-calendar {
  width: 100%;
  height: 350px;
  background-color: #ffffff14;
}
.app-checkbox {
  border: 2px solid white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.app-task-today {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 400;
}
