.diy-bg {
  background-image: url("../../assets/images/Gradient.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Inter", sans-serif;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.diy-home {
  height: 100%;
  min-height: 100vh;
}
.diy-content {
  height: 100%;
  font-family: "Inter", sans-serif;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.diy-content .diy-title {
  font-size: 50px;
  margin: 0;
}
.diy-content .diy-detail {
  color: #262528b2;
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .diy-content .diy-title {
    font-size: 30px;
  }
  .diy-content .diy-detail {
    font-size: 14px;
  }
}
.diy-logo-container .leap-learning {
  width: auto;
  height: 50px;
}
@media (max-width: 468px) {
  .diy-logo-container {
    padding: 10px;
  }
  .diy-logo-container .leap-learning {
    height: auto;
    width: 100%;
  }
}
