.category-journey-wrapper {
  border: 1px solid #e6e9f1;
  box-shadow: 6px 6px 24px 0px #5a668314;
  border-radius: 8px;
}
.category-journey-wrapper .journey-header {
  padding: 20px;
}
.category-journeys .category-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.category-journeys .category-title h2 {
  margin: 0;
}
.category-title .ant-tag {
  border-radius: 4px;
  padding: 0px 8px;
}
.category-actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
.category-actions svg {
  cursor: pointer;
}
.category-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  position: sticky;
  top: 64px;
  z-index: 10;
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid #e6e9f1;
}
.total-journeys {
  color: #475467;
}
