.category-tag {
  background-color: white;
  color: #6a6a6a;
  border-color: #2b36741f;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 12px;
}
.status-tag {
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 12px;
}
.status-tag.published {
  background-color: #00c54f;
  color: #ffffff;
}
.status-tag.draft {
  background-color: #edeff5;
}
.type-tag {
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 12px;
}
.type-tag.pre-defined {
  background-color: #b1f4dc;
}
.type-tag.fixed {
  background-color: #c9d0f9;
}
.status-tags {
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 12px;
}
.status-tags.published {
  background-color: #b1f4dc;
}
.status-tags.draft {
  background-color: #edeff5;
}
.ant-tag {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
