.signup-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.signup-box {
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.signup-content {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.signup-form-container {
  margin-top: 20px;
}

.name-inputs {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.nameInput {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
  border-radius: 8px;
}

.name-inputs .ant-form-item {
  flex: 1;
  margin-top: 8px;
}

.name-inputs .ant-input-affix-wrapper {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}

.name-inputs .ant-input-affix-wrapper:hover,
.name-inputs .ant-input-affix-wrapper:active {
  box-shadow: none;
}

.name-inputs .ant-form-item-explain-error {
  font-size: 12px;
}

.submit-button {
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.signup-container .ant-input-affix-wrapper {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}

.signup-container .ant-input-affix-wrapper:hover,
.signup-container .ant-input-affix-wrapper:active {
  box-shadow: none;
}

.signup-container .ant-form-item-explain-error {
  font-size: 12px;
}

@media (max-width: 468px) {
  .signup-form-container,
  .signup-box {
    width: 100%;
  }
}
.success-signup h2 {
  margin: 10px 0;
  font-size: 24px;
}
.leaproad-logo-signup {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.success-signup h3 {
  font-size: 20px;
}
.success-signup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}
