.pagination-wrapper{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;
}
.pagination-wrapper .ant-pagination-item-active{
    border: none;
}
.pagination-container{
    flex: 1;
}
.pagination-wrapper button{
    box-shadow: 0px 1px 2px 0px #1018280D;
    height: 36px;
    padding: 5px 12px;
    color: #344054;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}
.pagination-wrapper button.next{
    flex-direction: row-reverse;
}