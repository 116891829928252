.question-form {
  margin-top: 0;
}
.question-form .index {
  color: #00000073;
}
.question-form .diy-question {
  font-size: 24px;
  font-weight: 600;
}
.question-form .answer-input {
  resize: none;
  border: 1px solid #00000073;
}
.question-form .ant-form-item-explain {
  text-align: left;
}
.ant-skeleton.ant-skeleton-element .skeleton-loader.ant-skeleton-button {
  min-width: fit-content;
  width: 100%;
}
.question-actions button {
  width: 50%;
}
.question-progress {
  width: 100%;
  height: 6px;
  background-color: #d9d9d9;
  border-radius: 999px;
}
.question-progress.completed {
  background-color: #262528;
}
.diy-email {
  padding-top: 50px;
}
.diy-email-input .ant-form-item-label {
  text-align: center;
}
.email-input {
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0;
  text-align: center;
}
.email-desc {
  font-size: 16px;
  text-align: center;
  color: #6a6a6a;
}
.result-desc {
  font-size: 16px;
  text-align: center;
  color: #6a6a6a;
  margin: 0;
}
.result-progress {
  margin: 0;
}
.celeb {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 20px 0;
}
.question-actions .chat-loader {
  width: 40px;
  aspect-ratio: 2;
  margin: 0 auto;
  --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@media (max-width: 768px) {
  .question-form .diy-question {
    font-size: 16px;
  }
  .diy-answer {
    font-size: 14px;
  }
  .diy-question-container {
    flex: 1;
  }
  .diy-question-container .diy-container {
    height: fit-content;
  }
  .diy-email-input {
    padding: 20px 0;
  }
}
