.instruction-title {
  font-size: 14px;
  font-weight: 600;
}
.instruction-container {
  background-color: #002b9014;
  padding: 24px 12px;
  border-radius: 8px;
}
.instruction-details {
  font-size: 12px;
}
