.projects {
  flex: 1;
  overflow: hidden;
}
.project-course {
  position: absolute;
  height: 100%;
  flex: 1;
  width: 100%;
  top: 0;
  right: 0;
  background: #eff3f5;
  overflow: hidden;
}
.project-course-container {
  overflow: hidden;
  height: 100%;
}
.project-course-header {
  position: sticky;
  padding: 20px 30px;
  border-bottom: 1px solid #e6e9f1;
}
.project-course-list {
  overflow: auto;
  height: 100%;
  padding: 10px;
  flex: 1;
}
.project-course-title {
  font-size: 16px;
  font-weight: 600;
}
.course-list {
  background: white;
  padding: 20px;
  text-align: left;
  border-radius: 6px;
  cursor: pointer;
}
.course-list:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.project-course-list .skeleton-loader {
  width: 100%;
}
.project-content-wrapper {
  padding: 20px;
  height: 100%;
}

.project-input-container {
  background: #eff3f5;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 5px #bebebe;
}

.project-input-container .project-input {
  background: #eff3f5;
  /* padding: 10px; */
  resize: none;
  font-size: 16px;
  border: none;
}
.infinite-scroll-component__outerdiv {
  width: 100%;
}
.course-list-container {
  margin-bottom: 10px;
  padding: 10px;
}
.project-content-list {
  overflow: hidden;
}
.project-list-wrapper {
  overflow: auto;
  width: 100%;
  padding: 10px;
}
.list-type {
  background: #eff3f5;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 15px 30px; */
  border-radius: 6px;
}
.project-list {
  width: 100%;
  padding: 20px;
}
.project-list:hover {
  width: 100%;
  background: #f8f8f8;
  border-radius: 6px;
}
.list-type {
  min-width: 100px;
  width: fit-content;
  height: 60px;
}
.list-detail {
  width: 60%;
}
.list-item {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-align: left;
}
.send-document {
  background-color: #ebebeb;
  box-shadow: 1px solid #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  cursor: pointer;
}
.send-document:hover.ant-btn-default:hover {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border: 1px solid transparent;
}
