.journey-detail {
  min-height: calc(400px);
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
.container {
  margin: 0 auto;
  max-width: 900px;
}
.journey-detail-header {
  border-bottom: 1px solid #002b9014;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 10;
}
.journey-header-container {
  padding: 32px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.journey-image {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  object-fit: cover;
}
.journey-details {
  display: flex;
  gap: 20px;
  align-items: center;
}
.journey-titles {
  display: flex;
  flex-direction: column;
}
.journey-titles h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.journey-type-status {
  display: flex;
  gap: 10px;
  align-items: center;
}
.journey-type-status .ant-tag {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 400;
}
.journey-detail button {
  height: 36px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.journey-detail-content {
  padding: 40px 24px;
}
.content-title {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.generated-question-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.detail-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.question-number {
  background-color: #3d3d3d;
  padding: 2px 8px;
  width: fit-content;
  color: #fff;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 13px;
  min-width: 24px;
}
.journey-detail-sections.pre-defined {
  margin-top: 80px;
}
.section-number {
  font-size: 14px;
  color: #6a6a6a;
  font-weight: 400;
}
.generated-milestones {
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.section-item-title {
  font-size: 16px;
  font-weight: 600;
  color: #a9a9a9;
  margin-bottom: 20px;
}
.generated-milestone-container {
  background-color: #f2f4f7;
  /* padding: 20px; */
  display: flex;
  gap: 20px;
  align-items: center;
  /* border-top: 1px solid rgb(0, 0, 0); */
}

.generated-prompt-container {
  background-color: #f2f4f7;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.milestone-details {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
}
.task-details {
  background-color: #f5f7fa;
  padding: 20px;
}
.generated-task-container {
  padding: 0 20px;
}
.generated-task-container li {
  color: #6a6a6a;
  font-size: 14px;
  font-weight: 400;
}
.empty-container {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.journey-detail-sections .content-title {
  margin-top: 15px;
}
.content {
  cursor: pointer;
}
.content-icon {
  width: 96px;
  height: 64px;
  background-color: #002b9014;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.content-logo {
  opacity: 0.6;
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.task-collapse-container {
  background-color: #e2e1e1;
  padding: 15px;
  border-radius: 8px;
}
.journey-detail-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.journey-detail-loader .loader {
  position: relative;
  top: auto;
  left: auto;
}
