.container-wrapper {
  background-color: #f7f9fc;
  display: flex;
  font-family: "Inter", sans-serif;
}
.form-container {
  /* width: 70%; */
  /* min-height: calc(100vh - 64px); */
  height: 100%;
  overflow: auto;
}
.form-configuration {
  width: 100%;
  /* height: 100%; */
}
.form-header {
  position: sticky;
  top: 0px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0;
  border-bottom: 1px solid #d5d5d5;
  background-color: #f7f9fc;
  z-index: 5;
  height: 120px;
}
.form-header .title {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 35%;
}
.title-input.ant-input,
.title-input.ant-input:focus,
.title-input.ant-input:disabled {
  border: 1px solid transparent;
  background-color: transparent;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-overflow: ellipsis;
  color: #000000;
  cursor: default;
}
.title-input.ant-input:hover {
  text-overflow: clip;
}
.title-input.active.ant-input {
  border: 1px solid #000000;
  cursor: text;
}
.form-header .edit {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.journey-action-buttons {
  display: flex;
  gap: 30px;
  align-items: center;
}
.journey-action-buttons button {
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  height: 36px;
  box-shadow: 0px 1px 1px 0px #0000001f,
    /* First shadow */ 0px 0px 0px 1px #676e7629,
    /* Second shadow */ 0px 2px 5px 0px #676e7614; /* Third shadow */
  cursor: pointer;
}
.save-button {
  background-color: #000000;
  color: white;
}
.form-wrapper {
  padding: 20px;
  margin-top: 30px;
  /* padding: 0px 200px 30px; */
}
.input-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #121212;
}
.section-sub-title {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}
.section-tast-title {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
.input-wrapper .delete,
.section-title-wrapper .delete {
  box-shadow: 0px 1px 1px 0px #0000001f,
    /* First shadow */ 0px 0px 0px 1px #676e7629,
    /* Second shadow */ 0px 2px 5px 0px #676e7614; /* Third shadow */
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  /* align-self: ; */
}
.form-wrapper .ant-input,
.form-wrapper .ant-input-number {
  height: 45px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #676e7629,
    0px 2px 5px 0px #676e7614;
}
.form-wrapper .ant-input:disabled,
.form-wrapper .ant-input-number:disabled {
  cursor: default;
}
.form-wrapper .ant-input-number-input {
  height: 45px;
}
.form-wrapper .add-button {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background: #0000000f;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.form-wrapper .add-button.video-upload {
  margin-bottom: 20px;
}
.section-container {
  margin-top: 30px;
}
.section-content-container {
  border-left: 1px solid #0000004d;
  padding-left: 30px;
  margin-top: 10px;
}
.milestone-form,
.prompt-form {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
}
.section-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
.section-title-wrapper .section-title {
  margin-bottom: 0;
}
.form-container .ant-form-item-explain-error {
  font-size: 12px;
}
.error-message {
  color: #ff4d4f;
  font-size: 12px;
}
.title-input-wrapper {
  display: flex;
  flex-direction: column;
}
.title-input-wrapper.overflow {
  overflow: hidden;
}
.tasks {
  padding-left: 30px;
  border-left: 1px solid #0000004d;
  margin-bottom: 20px;
}
.image-input {
  display: flex;
  justify-content: center;
  background-color: #e6eaef;
  padding: 40px;
  border-radius: 8px;
  min-height: 120px;
}
.image-input button {
  border: none;
  border-radius: 99px;
  padding: 8px 12px;
  height: auto;
  display: flex;
  align-items: center;
}
.tasks .task-estimate {
  display: flex;
  gap: 20px;
}
.last-update {
  color: #6a6a6a;
  font-size: 12px;
}
.back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: none;
  background-color: #dfe5ed;
  border-radius: 100%;
  cursor: pointer;
}
.loader {
  width: 100%;
  height: 100%;
  background-color: #0000006b;
  z-index: 1005;
  position: fixed;
}
/* .loader div {
  width: 4.5em;
  height: 4.5em;
  border: 3px solid transparent;
  border-top-color: #000000;
  border-bottom-color: #000000;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1005;
}
@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
} */
/* HTML: <div class="loader"></div> */
.loader-container {
  width: 70%;
  position: relative;
  height: calc(100vh - 64px);
}
.journey-loader-container {
  width: 100%;
}
.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.journey-loader-container .loader {
  left: 50%;
  top: 50%;
}
.loader {
  height: 80px;
  width: 80px;
  aspect-ratio: 1;
  position: absolute;
  border-radius: 50%;
  left: 30%;
  top: 35%;
  background: radial-gradient(farthest-side, #000000 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #000000);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
.journeys-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  font-weight: 600;
  padding: 13px 0;
}
.form-loader-container {
  position: fixed;
  width: 70%;
  height: 100%;
  z-index: 10;
  background-color: #9e9e9e73;
}
.form-wrapper .ant-form-item-label label {
  font-size: 12px;
  color: #6a6a6a;
}
.upload {
  margin-bottom: 20px;
}
