.add-training-video {
  float: inline-end;
  height: 36px;
  padding: 6px 20px;
}

.course-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: black;
  width: 20rem;
  display: block;
}
.course-title:hover {
  color: rgba(0, 0, 0, 0.664);
}
