.home-breadcrumb {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
.breadcrumb-wrapper .ant-breadcrumb-link:hover .home-breadcrumb {
  background-color: transparent;
}
.breadcrumb-wrapper .ant-breadcrumb-link {
  height: fit-content;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
