.course-lesson {
  background-color: #ffffff;
  border-right: 1px solid #002b9014;
  padding: 16px;
  height: 100%;
}
.course-lesson-title {
  color: #6a6a6a;
}
.lesson {
  padding: 12px;
  background-color: #f5f7fa;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-top: 12px;
}
.lesson.selected {
  background-color: #e4ebf6;
}
.status {
  font-size: 11px;
  padding: 4px 8px;
  background-color: #ced9ea;
  border-radius: 4px;
  width: fit-content;
}
.completed {
  background-color: #beebbd;
  color: #195418;
}
.created {
  background-color: #eadcb9;
  color: #70530c;
}
.lesson .lesson-title {
  font-weight: 600;
}
.lesson.selected .selected-lesson {
  background-color: #003d96;
}
.selected-lesson {
  height: 100%;
  width: 3px;
  position: absolute;
  left: 0;
  top: 0;
}
.course-attachment {
  font-size: 12px;
  color: #121212;
}
.lesson-container {
  flex: 1;
  overflow: auto;
  scrollbar-width: none;
}
.lesson-container .ant-skeleton.ant-skeleton-element {
  width: 100%;
}
