.lesson-chunk-container {
  background-color: #f2f4f6;
  border-radius: 8px;
  padding: 10px;
  border: 3px solid darkgray;
}
.lesson-chunk-container .generating {
  font-size: 14px;
  color: #6a6a6a;
}
