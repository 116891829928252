.chat-template {
  height: 100%;
  padding: 10px;
}
.chat-template-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chat-template-input-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
}
.chat-template-input {
  background: transparent !important;
  border: none;
  padding: 8px 0;
}
.chat-template-input:hover {
  background: transparent;
}
.chat-template-icons {
  font-size: 20px;
}
.chat-container {
  overflow: auto;
}
.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between messages */
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.chat-message {
  max-width: 70%;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  min-width: 100px;
  text-align: left;
}
.chat-message p {
  margin: 0;
}

/* Bot's messages aligned to the left */
.bot-message {
  align-self: flex-start;
  background-color: #e0e0e0;
  color: #333;
}

/* User's messages aligned to the right */
.my-message {
  align-self: flex-end;
  background-color: #cbd0d8;
}
.chat-container-col {
  display: flex;
  flex-direction: column;
}
.chat-container-row::-webkit-scrollbar {
  width: 5px;
}

.chat-container-row::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.chat-container-row::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.chat-container-wrapper {
  overflow: hidden;
}
.chat-container-row {
  overflow-y: auto;
  position: relative;
  height: 100%;
}
.scroll-button {
  position: sticky; /* Keeps the button in a fixed position */
  left: 90%;
  bottom: 20px; /* Adjusts the distance from the bottom of the viewport */
  background-color: #333; /* Blue background */
  color: white; /* White icon/text */
  border: none; /* Removes the border */
  border-radius: 50%; /* Makes the button circular */
  width: 50px; /* Sets width */
  height: 50px; /* Sets height */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Adds shadow for a floating effect */
  cursor: pointer;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0; /* Hidden by default */
  visibility: hidden; /* Prevents interactions when hidden */
  z-index: 1000; /* Keeps it above other elements */
}

.scroll-button.visible {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Allows interaction */
}

.scroll-button:hover {
  background-color: #333; /* Darker blue on hover */
  transform: scale(1.1); /* Slightly enlarges the button */
}
.uploaded-file {
  background: #f4c6c6;
  padding: 10px;
  border-radius: 6px;
}
.doc-file-icon {
  font-size: 20px;
  font-weight: 800;
}
.project-list {
  align-self: flex-end;
  background-color: #f2e5e5;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.template-content {
  background-color: #fff;
  padding: 10px;
}
