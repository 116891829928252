.usecase-script-container {
  background-color: #ffffff;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
}
.script-input{
    border: 1px solid #002b9014;
}
.usecase-editor-wrapper{
  border: 1px solid #002b9014;
  padding: 10px;
  border-radius: 6px;
}
.usecase-editor-wrapper .DraftEditor-editorContainer{
  line-height: 1.8;
}
.DraftEditor-root,.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content{
  height: 100% !important;
}