.ant-modal-footer .action-buttons,
.ant-drawer .action-buttons {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
.ant-modal-footer .action-buttons button,
.ant-drawer .action-buttons button {
  width: 40%;
}
.ant-modal-footer .action-buttons .cancel-button,
.ant-drawer-footer .action-buttons .cancel-button {
  border: none;
  box-shadow: none;
}
.action-buttons.delete {
  justify-content: flex-end;
}
.ant-modal .ant-input,
.ant-modal .ant-select,
.ant-drawer .ant-input,
.ant-input-group-addon {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}
.ant-modal .ant-modal-body {
  padding: 20px 0;
}
.ant-modal-body .input-label {
  margin: 10px 0;
}
.ant-modal .ant-modal-content {
  border-radius: 12px;
}
.image-thumbnail {
  margin: 0 auto;
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}
.ant-radio-group {
  display: flex;
  gap: 20px;
}
.journey-type {
  padding: 12px 20px;
  border: 1px solid transparent;
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #676e7629,
    0px 2px 5px 0px #676e7614;

  border-radius: 8px;
  width: 40%;
  cursor: pointer;
}
.journey-type.active {
  border: 1px solid #262528;
}
.ant-modal .ant-select {
  border-radius: 8px;
}
.ant-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}
.ant-drawer .ant-drawer-header {
  padding: 30px 24px;
}
.ant-drawer .ant-drawer-title {
  font-size: 24px;
}
.drawer-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  overflow: auto;
}
.type-filter-buttons button {
  height: 32px;
  padding: 4px 12px;
  border-radius: 8px;
}
.type-filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.category-filter {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ant-drawer-body {
  overflow: hidden;
}
.categories {
  overflow: auto;
  flex: 1;
}
.categories .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.categories .ant-checkbox-group .ant-checkbox-wrapper {
  padding: 10px 0;
}
.category-filter .ant-input {
  margin: 0 2px;
  width: auto;
}
.category-filter .lds-ring {
  height: 100%;
}
.category-filter .lds-ring div {
  border: 2px solid #000000;
  border-color: #000000 transparent transparent transparent;
}
.ant-modal-footer .action-buttons .cancel-button.shadow {
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #676e7629,
    0px 2px 5px 0px #676e7614;
}
.ant-modal-footer .action-buttons .discard-button {
  box-shadow: none;
  color: #e91f1f;
  border: none;
}
.thumbnail-upload {
  padding: 40px;
  border: 1px dashed #cbd0dc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  border-radius: 12px;
}
.preview-thumbnail {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
}
.thumbnail-upload button {
  font-weight: 600;
}
.browse {
  font-size: 14px;
  font-weight: 500;
}
.supported-files {
  font-size: 12px;
  font-weight: 400;
  color: #a9acb4;
}
.image-name {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.image-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.image-size {
  font-size: 12px;
  font-weight: 400;
  color: #667091;
}
.video-loader {
  height: fit-content;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-loader .loader {
  position: relative;
  top: auto;
  left: auto;
}
iframe img {
  margin: 0 auto;
}
.password-input {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}
.password-input .ant-input {
  box-shadow: none;
}
.add-user .ant-form-item-explain-error {
  font-size: 12px;
}
.content-display {
  min-height: 600px;
}
.content-display .ant-modal-body {
  height: 500px;
}

.content-display iframe {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}
.content-display img {
  max-height: 400px;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.video-options {
  margin: 0 0 20px;
}
.upload-loader-container {
  height: 200px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.upload-loader-container .loader {
  position: relative;
}
.avatar-profiles-row {
  padding: 0 10px;
}
.avatar-col {
  padding: 0px 10px;
}
.avatar-card {
  padding: 5px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatar-card.selected {
  border: 1px solid black;
}
.avatar-image {
  height: 150px;
  object-fit: contain;
  margin-bottom: 8px;
  background-color: #a9acb4;
  width: auto;
  max-width: 100%;
}
.avatar-meta {
  text-align: center;
}
.avatar-card .ant-card-body {
  width: 100%;
  box-shadow: none;
}
.audio-profile-modal__item {
  display: flex;
  align-items: center;
}
.audio-profile-modal__play-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}
.audio-profile-modal__name {
  margin-right: 10px;
}
.audio-profile-modal__audio-control {
  margin-left: 10px;
  display: none;
}
.audio-profile-modal__select-button {
  margin-left: 10px;
  background-color: transparent;
  color: black;
  border: 1px solid;
  padding: 5px 10px;
  width: 80px;
  border-radius: 3px;
  cursor: pointer;
}
.audio-profile-modal__select-button.audio-profile-modal__selected {
  background-color: black;
  color: white;
}
/* ///////////////////////add organisation modal /////////////////////// */
.org-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.org-row {
  display: flex;
  gap: 15px;
}

.org-input {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.org-input-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 500;
}

.org-error-message {
  color: #f5222d;
  font-size: 12px;
  margin-top: 5px;
}

.org-action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.org-cancel-button {
  color: #595959;
}

/* ///////////////////////add organisation modal /////////////////////// */

.organization-roles .journey-type {
  width: 30%;
}
.project-file-container {
  max-height: 400px;
  overflow: auto;
  padding: 10px 20px;
}
.delete-project-file {
  font-size: 20px;
  color: red;
  cursor: pointer;
}
.project-document {
  max-height: 600px;
}
.document-name {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
}
.doument-detail {
  overflow: hidden;
}
.doument-detail svg {
  min-width: 64px;
}
.record-video {
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}
.record-video:hover {
  border: 1px dashed #5087ff;
}
.state-filter .ant-select {
  width: 100%;
}
.edit_organisation_data {
  padding: 10px;
  padding: 10px 10px 10px 5px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgba(128, 128, 128, 0.219);
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.03);
}
.create-category-button-withLoading {
  min-width: 180px;
}
.failed-doc {
  color: red;
}
.organization-list-select .ant-select-selector {
  max-height: 250px;
  overflow: hidden;
}
.organization-list-select .ant-select-selection-overflow {
  max-height: 250px;
  overflow: auto;
}
.share-resource {
  font-size: 20px;
}
.share-resource .share-to {
  font-size: 14px;
  width: fit-content;
}
.shared-item {
  font-size: 14px;
  font-weight: 400;
}
.add-course-modal .answer-input {
  box-shadow: 0px 0px 0px 1px #676e7629, 0px 2px 5px 0px #3045ff14;
}
.org-logo.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.org-logo .ant-upload-list-item-container {
  width: 100% !important;
}
.theme-display .ant-modal-content,
.theme-modal,
.theme-display .ant-modal-body {
  padding: 0;
}
.theme-display .ant-modal-body {
  height: 90vh;
  min-height: 600px;
}

.theme-display .ant-modal-close-x .anticon {
  font-size: 30px;
  color: white;
}
.avatar-uploader {
  width: 100%;
}
.audio-preview-button {
  border: none;
  padding: 0;
  height: fit-content;
  background-color: transparent !important;
}

.audio-preview-button * {
  font-size: 20px;
}
