.video-usecase {
  flex: 1;
  padding: 20px 0;
  overflow: hidden;
}
.use-case {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.usecase-content {
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 2px 3px 12px 0px #0000000a;
  border: 1px solid #002b9014;
  height: 100%;
  overflow: hidden;
}
.usecase-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.usecase-container {
  overflow: auto;
  padding: 10px;
  flex: 1;
}
.usecase-header {
  border-bottom: 1px solid #002b9014;
  color: #6a6a6a;
  font-size: 14px;
  padding: 24px;
  text-align: left;
}
.usecase-footer {
  border-top: 1px solid #002b9014;
  color: #6a6a6a;
  font-size: 14px;
  padding: 24px;
}
.usecase-header-title {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.usecase-title-input {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid;
}
.edit-title {
  display: flex;
  align-items: center;
}

.chatbot-button {
  color: white;
  border: solid #002b9014 2px;
  background-color: 002b9014;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 3px 12px 0px #0000000a;
}

.chatbot-popup {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50vw;
  height: 86vh;
  background-color: white;
  border-radius: 16px;
  box-shadow: 2px 3px 12px 0px #00000081;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s ease;
}

.chatbot-popup.open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.chatbot-popup.closed {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
}
.chatbot-billi {
  width: fit-content;
  padding: 0 20px;
  color: black;
  height: 50px;
  border-radius: 9999px;
  background-color: #ffffff;
}
.chatbot-button:hover {
  color: white;
  background-color: black;
}
.chatbot-button:hover .ant-float-btn-body .ant-float-btn-icon {
  color: white;
}

.add-entity {
  padding: 3px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.add-entity .entity-title {
  display: none;
}
.add-entity:hover .entity-title {
  display: contents;
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .usecase-header {
    padding: 12px;
  }
  .usecase-footer {
    padding: 12px;
  }
  .edit-title {
    padding: 3px 12px;
    font-size: 14px;
  }
  .chatbot-button {
    position: fixed;
    bottom: 10px;
    right: 15px;
  }
  .chatbot-popup {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 80vh;
  }
}
