.course-outline {
  background: #f5f7fa;
  padding: 20px;
  border-radius: 8px;
}
.course-outline-title {
  font-size: 16px;
  font-weight: 600;
}
.project-content {
  height: fit-content;
  overflow: hidden;
}
