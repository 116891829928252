.diy-form-container {
  padding: 40px;
  height: 100%;
}
.diy-form-container.diy-home {
  min-height: calc(100vh - 105px);
}
.diy-steps {
  height: 100%;
  box-sizing: border-box;
}
.diy-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
}
.diy-video-title {
  color: #24292e;
  font-weight: 600;
  font-size: 20px;
}
.error {
  color: #ff4d4f;
}
@media (max-width: 768px) {
  .diy-form-container,
  .diy-container {
    padding: 40px 16px;
  }
  .diy-steps .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
  .diy-steps .ant-steps-item-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .diy-steps .ant-steps-item-title {
    font-size: 14px;
  }
  .diy-steps .diy-title-wrapper {
    flex: 1;
    justify-content: center;
  }
}
.diy-logo {
  padding: 30px;
  position: relative;
  z-index: 100;
}
.loader-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.diy-logo-container.diy-logo .leap-learning {
  height: 45px;
}
@media (max-width: 468px) {
  .diy-logo-container.diy-logo .leap-learning {
    height: 30px;
    width: auto;
  }
}
