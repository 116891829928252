.course-detail {
  padding: 16px;
  height: 100%;
  overflow: hidden;
}
.course-detail-container {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 2px 3px 12px 0px #0000000a;
  border: 1px solid #002b9014;
  height: 100%;
}
.course-detail-header {
  padding: 24px;
  color: #6a6a6a;
  border-bottom: 1px solid #002b9014;
  font-size: 14px;
}
.course-header-title {
  font-size: 16px;
  font-weight: 500;
}
.course-detail-footer {
  padding: 24px;
  color: #6a6a6a;
  border-top: 1px solid #002b9014;
  font-size: 14px;
}
.course-content {
  padding: 24px;
  overflow: auto;
  /* scrollbar-width: none; */
}

.course-content::-webkit-scrollbar {
  width: 5px;
}

.course-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.course-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.close-box {
  cursor: pointer;
}
.course-detail-form {
  height: fit-content;
  width: 100%;
}
.course-detail-form input,
.course-detail-form textarea,
.course-content .answer-input {
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #676e7629,
    0px 2px 5px 0px #676e7614;
}
.script-result-container {
  border: 1px solid #e6e7ed;
  padding: 20px;
  border-radius: 6px;
}
.script-result-container .script-result {
  background-color: #eff2f5;
  padding: 12px;
  border-radius: 8px;
}
