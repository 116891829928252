.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-color: #f4f4f4;
}

.leaproadLogo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.loadingContent {
  text-align: center;
  color: #333;
  font-size: 18px;
  padding: 20px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContent p {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.successSection {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.successSection h2 {
  margin: 10px 0;
  font-size: 24px;
}

.successSection h3 {
  font-size: 20px;
}

