.table-action {
  display: flex;
  gap: 20px;
  align-items: center;
}
.table-action > * {
  cursor: pointer;
}
.shared-info {
  font-size: 18px;
  font-weight: 500;
  color: #475467;
}
