.ant-layout-header {
  z-index: 10;
  height: fit-content;
  line-height: normal;
  border-bottom: 1px solid #e6e9f1;
  box-shadow: 6px 6px 24px 0px #5a668314;
}
.header {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  background-color: #edeff5;
  padding: 5px 10px;
  border-radius: 8px;
}
.user-details .ant-skeleton.ant-skeleton-element {
  width: fit-content;
}

.user-details .ant-skeleton.ant-skeleton-element.ant-skeleton-block {
  height: 35px;
  width: 200px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.logo-container a {
  display: flex;
}

.logo-container .ant-breadcrumb-link {
  display: inline-block;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-breadcrumb li {
  display: flex;
  align-items: center;
}
.navbar-menu {
  margin: 20px;
  height: fit-content;
  border-radius: 10px;
}
